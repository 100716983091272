import React from 'react';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';

import Bio from '../components/Bio';


export default class NotFoundPage extends React.Component {
    render() {
        const siteMetadata = this.props.data.site.siteMetadata;

        return (
            <>
                <Helmet title={`Page not found | ${siteMetadata.title}`}><html lang="en"/></Helmet>
                <main>
                    <h1>NOT FOUND</h1>
                    <p>You just hit a page that doesn&#39;t exist.</p>
                    <p>You may go to the <Link to='/'>homepage</Link></p>
                </main>
                <aside>
                    <Bio person={siteMetadata.author} />
                </aside>
            </>
        );
    }
}

export const pageQuery = graphql`
  query NotFoundMetadata {
    site {
      siteMetadata {
        title
        author { ...BioPerson }
      }
    }
  }
`
